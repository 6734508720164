import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import BaseDashboardInfoCard from '@/components/BaseComponents/BaseDashboardInfoCard.vue'
import CircularOverlay from './components/CircularOverlay.vue'
import './registerServiceWorker'
import moment from 'moment/src/moment'
import VueFriendlyIframe from 'vue-friendly-iframe'
import DatetimePicker from 'vuetify-datetime-picker'

import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';
import { Amplify, API, Auth, Storage } from 'aws-amplify';
Amplify.register(API)
Amplify.register(Storage)
Amplify.register(Auth)
/* Register the services before configure */

Amplify.configure(aws_exports);

//global registration
Vue.component('BaseDashboardInfoCard', BaseDashboardInfoCard)
Vue.component('CircularOverlay', CircularOverlay)

Vue.config.productionTip = false

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY')
  }
})

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY, hh:mm')
  }
})

Vue.filter('formatAmount', function(value) {
  if (value) {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(value)
  }
})

Vue.use(VueFriendlyIframe)
Vue.use(DatetimePicker)

new Vue({
  router,
  store,
  vuetify,
  //sets user state to current localstorage in case of refresh
  created() {
    const userString = localStorage.getItem('user')
    if(userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('SET_USER_DATA', userData)
    }

    // *** saftey measure to avoid use of fake tolkens
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  },
  render: h => h(App)
}).$mount('#app')
