import axios from 'axios'

export default {
  namespaced: true,
  state: {
    landlords: '',
  },
  mutations: {
    SET_LANDLORDS(state, data) {
      state.landlords = data
    },
  },
	actions :  {
		// Multiple Axios request -----------------------
		getLandlordsCount({commit, dispatch}) {
			return axios.all([
			dispatch('getLandlordsBankless'),
			dispatch('getLandlordsEmailless'),
			dispatch('getLandlordsOwing'),
			dispatch('getLandlordsTenPercentBalance'),
			])
			.then(axios.spread( (bankless, emailless, owing, tenPercentDiff) => {
					commit('SET_LANDLORDS', {
            bankless: bankless.data,
            emailless: emailless.data,
            owing: owing.data,
            tenPercentDiff: tenPercentDiff.data,
					})

			}))
    },
    // Following APIs called simultaneaously
		getLandlordsBankless({rootState}) {
			return axios({
				method: 'GET',
				url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/landlords/bankless/count`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
			})
		},
		getLandlordsEmailless({rootState}) {
			return axios({
				method: 'GET',
				url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/landlords/emailless/count`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
			})
		},
		getLandlordsOwing({rootState}) {
			return axios({
				method: 'GET',
				url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/landlords/owing/count`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
			})
		},
		getLandlordsTenPercentBalance({rootState}) {
			return axios({
				method: 'GET',
				url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/landlords/tenPercentBalanceDifference/count`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
			})
		},
	}
}