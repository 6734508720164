import axios from 'axios'

export default {
  namespaced: true,
  state: {
    submittedDeductionRecords: ''
  },
  mutations: {
    SET_SUBMITTED_DEDUCTIONS(state, data) {
      state.submittedDeductionRecords = data
    }
  },
  actions: {
    downloadDocument({rootState}, payload) { // for downloading any kind of document through OS
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/download/document?documentID=${payload}`,
        responseType: 'blob',
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        }
      })
    },
    getSubmittedDeductionRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/contractors/deductions`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_SUBMITTED_DEDUCTIONS', data.Data)
        })
    },
    authoriseContractorInvoice({rootState}, deductionID) {
      return axios({
        method: 'POST',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/contractors/deductions/${deductionID}/authorise`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
    },
    rejectContractorInvoice({rootState}, deductionID) {
      return axios({
        method: 'POST',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/contractors/deductions/${deductionID}/reject`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
    },
    archiveContractorInvoice({rootState}, deductionID) {
      return axios({
        method: 'POST',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/contractors/deductions/${deductionID}/archive`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
    }
  }
}