import axios from 'axios'

export default {
  namespaced: true,
  state: {
    currencyFilterData: ''
  },
  mutations: {
    SET_CURRENCY_DATA(state, data) {
      localStorage.setItem('currency', JSON.stringify(data))
      state.currencyFilterData = data
    }
  },
  actions: {
    getCurrencyData({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/company/currency`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
      })
        .then(({data}) => {
        console.log(data);
          commit('SET_CURRENCY_DATA', data)
        })
    }
  },
  getters: {
    currencyInfo(state){
      if(state.currencyFilterData === null || state.currencyFilterData === undefined || state.currencyFilterData === ''){
        state.currencyFilterData = JSON.parse(localStorage.getItem('currency'))
      }
      console.log(state.currencyFilterData);
      return state.currencyFilterData;
    }
  }
}