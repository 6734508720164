// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#eb5c37',
        secondary: '#40424c',
        // tertiary: '#F5F5F5',
        tertiary: '#f0f0f0',
        dialogColor: '#eb5c37',
        accent: '#fc2b03',
        error: '#b71c1c',
        tabColor: '#eb5c37',
        tableColor: ''
      },
      dark: {
        // dark theme inspiration
        // https://colorhunt.co/palette/0820322c394b334756ff4c29
        primary: '#082032',
        secondary: '#FF4C29', // vibrant orange
        // secondary: '#eb5c37', // light primary color
        tertiary: '#334756',
        dialogColor: '#fc2b03',
        accent: '#fc2b03',
        error: '#b71c1c',
        tabColor: '#ffffff',
        tableColor: '#082032'
      },
    },
  },
}

export default new Vuetify(opts)
