import axios from 'axios'

export default {
  namespaced: true,
  state: {
    accounts: '',
    records: ''
  },
  mutations: {
    SET_ACCOUNTS(state, data) {
      state.accounts = data
    },
    SET_RECORDS(state, data) {
      state.records = data
    },
    CLEAR_RECORDS(state) {
      state.records = ''
    },
  },
  actions: {
    getAccounts({commit, rootState}, payload) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/reporting/depositsheld/accounts`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID,
          searchDate: payload
        }
      })
      .then(({data}) => {
        commit('SET_ACCOUNTS', data)
      })
      .catch( err => console.log(err))
    },
    getRecords({commit, rootState}, payload) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/reporting/depositsheld/${payload.scheme}/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID,
          searchDate: payload.searchDate
        }
      })
      .then(({data}) => {
        commit('SET_RECORDS', data)
      })
      .catch( err => console.log(err))
    },
    exportRecords({rootState}, payload) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/reporting/depositsheld/${payload.scheme}/records/export`,
        responseType: 'blob',
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID,
          searchDate: payload.searchDate
        }
      })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'depositsHeldExport.CSV');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch( err => console.log(err))
    },
  }
}