import axios from 'axios'

export default {
  namespaced: true,
  state: {
    contractorsAll: '',
    bankless: '',
    emailless: '',
    onHold: '',
  },
  mutations: {
    SET_CONTRACTORS_ALL(state, data) {
      state.contractorsAll = data
    },
    SET_CONTRACTORS_BANKLESS_RECORDS(state, data) {
      state.bankless = data
    },
    SET_CONTRACTORS_EMAILLESS_RECORDS(state, data) {
      state.emailless = data
    },
    SET_CONTRACTORS_ON_HOLD(state, data) {
      state.onHold = data
    }
  },
  actions: {
    getAllContractorsRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/contractors/all/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_CONTRACTORS_ALL', data)
        })
    },
    getContractorsBanklessRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/contractors/bankless/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_CONTRACTORS_BANKLESS_RECORDS', data)
        })
    },
    getContractorsEmaillessRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/contractors/emailess/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_CONTRACTORS_EMAILLESS_RECORDS', data)
        })
    },
    getContractorsOnHold({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/contractors/onHold/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_CONTRACTORS_ON_HOLD', data)
        })
    }
  }
}