import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// modules
import DashboardCards from './modules/DashboardCards'
import LandlordsCount from './modules/Landlords/LandlordsCount'
import LandlordsRecords from './modules/Landlords/LandlordsRecords'
import TenantsCount from './modules/Tenants/TenantsCount'
import TenantsRecords from './modules/Tenants/TenantsRecords'
import PropertiesCount from './modules/Properties/PropertiesCount'
import PropertiesRecords from './modules/Properties/PropertiesRecords'
import ContractorsCount from './modules/Contractors/ContractorsCount'
import ContractorsRecords from './modules/Contractors/ContractorsRecords'
import OutgoingPayments from './modules/OutgoingPayments'
import UnreconciledPayments from './modules/UnreconciledPayments'
import PaidInvoices from './modules/Reports/PaidInvoices'
import UnpaidInvoices from './modules/Reports/UnpaidInvoices'
import DepositsHeld from './modules/Reports/DepositsHeld'
import OpenBankingRequests from './modules/OpenBanking/OpenBankingRequests'
import InvoiceRecords from './modules/Invoices/InvoiceRecords'
import PaymentRequests from './modules/PaymentRequests'
import RStB from './modules/Reports/RStB'
import CurrencyData from './modules/Currency/CurrencyData'
import ManagementFeeForecast from './modules/Forecast/ManagementFeeForecast'
import {authAgentOSSignIn, authAgentOSSignOut} from './agentosAuth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    DashboardCards,
    LandlordsCount,
    LandlordsRecords,
    TenantsCount,
    TenantsRecords,
    PropertiesCount,
    PropertiesRecords,
    OutgoingPayments,
    UnreconciledPayments,
    PaidInvoices,
    DepositsHeld,
    ContractorsCount,
    ContractorsRecords,
    OpenBankingRequests,
    InvoiceRecords,
    PaymentRequests,
    UnpaidInvoices,
    RStB,
    CurrencyData,
    ManagementFeeForecast
  },
  state: {
    // test line
    //Dev API
    // ------------------------------------------
    //baseURL: 'https://test2-api.letmc.com/v1',
    //apiKey: 'yrR5n2TUHYH44ybHgYhGdre5k-ze6fUwLI7UTNzKjuI1',
     
    //Live API
    // ------------------------------------------
    baseURL: 'https://live-api.letmc.com/v1',
    apiKey: 'PNxlzLihySfbSATZwUb_VEfgfaCEkn7RoCahHcmXwvE1',

    userSpecific: 0,
    user: null,
    activeUser: '',
    branchID: null,
    branchName: 'Company Wide',
    multiBranchAccess: false,
    allBranches: [],
    errorMessages: [],
    alternativeDashboard: false,
    lightTheme: true,
    salesChargeTypes: '',
    nominalCodes: ''
  },
  mutations: {
    SET_ERROR(state, data) {
      state.errorMessages.push(data)
    },
    SET_USER_DATA(state, userData) {
      state.user = userData
      state.user["loginTime"] = Date.now();
      localStorage.setItem('user', JSON.stringify(userData))
      authAgentOSSignIn(userData.UserOID, userData.UserDisplayName, userData.UserToken);
    },
    EXPIRE_LOGIN(state){
      const then = new Date(state.user.loginTime)
      const now = new Date();

      const msBetweenDates = Math.abs(then.getTime() - now.getTime());

      // 👇️ convert ms to hours                  min  sec   ms
      const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
      console.log(hoursBetweenDates);
      if (hoursBetweenDates > 23) {
        state.user = null;
        localStorage.setItem('user', null);
      }
    },
    CLEAR_USER_DATA(state) {
      state.userSpecific = '',
      state.user = null,
      state.activeUser = '',
      state.branchID = null,
      state.branchName = 'Company Wide',
      state.multiBranchAccess = false,
      state.allBranches = [],
      state.errorMessages = [],

      //Dashboard
      state.DashboardCards.outGoingPayments = '',
      state.DashboardCards.unreconciledPayments = '',
      state.DashboardCards.insights = '',
      state.DashboardCards.income = '',
      state.DashboardCards.portfolioGrowth = '',
      state.LandlordsCount.landlords = '',
      state.TenantsCount.tenants = '',
      state.PropertiesCount.properties = '',
      state.ContractorsCount.contractors = '',

      //Unreconciled & reconciled records
      state.UnreconciledPayments.unreconciledPaymentsRecords = '',
      state.UnreconciledPayments.reconciledPaymentsRecords = '',
     
      // Outgoing payments
      state.OutgoingPayments.landlordsToPay = '',
      state.OutgoingPayments.contractorsToPay = '',
      state.OutgoingPayments.tenantsToPay = '',
      
      //Property records
      state.PropertiesRecords.propertiesManagedRecords = '',
      state.PropertiesRecords.propertiesWithoutOwnerRecords = '',
      state.PropertiesRecords.propertiesRentCollectedRecords = '',
      state.PropertiesRecords.propertiesWithoutTenantsRecords = '',
      state.PropertiesRecords.propertiesNotManagedRecords = '',
      state.PropertiesRecords.propertiesNewTenanciesRecords = '',
      
      //Tenants records
      state.TenantsRecords.tenantsActiveRecords = '',
      state.TenantsRecords.tenantsArrearsRecords = '',
      state.TenantsRecords.tenantsNotChasedRecords = '',

      //Landlord records
      state.LandlordsRecords.landlordsEmaillessRecords = '',
      state.LandlordsRecords.landlordsOwingRecords = '',
      state.LandlordsRecords.landlordsTenPercentBalanceDifference = '',
      state.LandlordsRecords.landlordsBanklessRecords = '',
      state.LandlordsRecords.allLandlordRecords = '',

      //Contractor records
      state.ContractorsRecords.contractorsAll = '',
      state.ContractorsRecords.bankless = '',
      state.ContractorsRecords.emailless = '',
      state.ContractorsRecords.onHold = '',

      // Reports
      state.PaidInvoices.paidInvoices = ''
      state.DepositsHeld.accounts = ''
      state.DepositsHeld.records = ''

      //Open banking
      state.OpenBankingRequests.bankAccounts = ''

      state.salesChargeTypes = ''
      state.nominalCodes = ''

      localStorage.removeItem('user')
      localStorage.removeItem('currency')
      axios.defaults.headers.common['Authorization'] = null

      authAgentOSSignOut();
    },
    SET_SINGLE_BRANCH_USER(state, data) {
      state.activeUser = data.ActiveUser
      state.branchID = data.ManagingBranchOID
    },
    SET_MULTI_BRANCH_USER(state, data) {
      state.activeUser = data.ActiveUser
      state.multiBranchAccess = data.MultiBranchAccess
    },
    SET_SELECTED_BRANCH(state, data) {
      state.branchID = data.OID
      state.branchName = data.Name
    },
    SET_ALL_BRANCHES(state, data) {
      state.allBranches = data
    },
    SET_ALTERNATIVE_DASHBOARD(state, data) {
      state.alternativeDashboard = data
    },
    SET_LIGHT_THEME(state, data) {
      state.lightTheme = data
    },
    SET_SALES_CHARGE_TYPES(state, data) {
      state.salesChargeTypes = data
    },
    SET_NOMINAL_CODES(state, data) {
      state.nominalCodes = data
    }
  },
  actions: {
    fetchDashboardData({ dispatch }) {
      //Dashboard cards
      dispatch('DashboardCards/getUnreconciledPayments')
      dispatch('DashboardCards/getOutGoingPayments')
      dispatch('DashboardCards/getInsights')
      dispatch('DashboardCards/getIncome')
      dispatch('DashboardCards/getPortfolioGrowth')
      // Dashboard count figures
      dispatch('PropertiesCount/getPropertiesCount')
      dispatch('LandlordsCount/getLandlordsCount')
      dispatch('TenantsCount/getTenantsCount')
      dispatch('ContractorsCount/getContractorsCount')
    },
    fetchOutgoingPaymentsData({ dispatch }) {
      dispatch('OutgoingPayments/getLandlordsPayments')
      dispatch('OutgoingPayments/getTenantsPayments')
      dispatch('OutgoingPayments/getContractorsPayments')
      dispatch('OutgoingPayments/getZeroBalancePayments')
    },
    fetchUnreconciledsPaymentsData({ dispatch }) {
      dispatch('UnreconciledPayments/getUnreconciledPaymentsRecords')
      dispatch('UnreconciledPayments/getReconciledPaymentsRecords')
    },
    fetchTenantsRecords({ dispatch }) {
      dispatch('TenantsRecords/getTenantsActiveRecords')
      dispatch('TenantsRecords/getTenantsArrearsRecords')
      dispatch('TenantsRecords/getTenantsNotChasedRecords')
      dispatch('TenantsRecords/getTenantsHistoricRecords')
    },
    fetchPropertyRecords({ dispatch }) {
      dispatch('PropertiesRecords/getPropertiesManagedRecords')
      dispatch('PropertiesRecords/getPropertiesOwnerlessRecords')
      dispatch('PropertiesRecords/getPropertiesRentCollectedRecords')
      dispatch('PropertiesRecords/getPropertiesWithoutTenantsRecords')
      dispatch('PropertiesRecords/getPropertiesNotManagedRecords')
      dispatch('PropertiesRecords/getPropertiesNewTenanciesRecords')
      dispatch('PropertiesRecords/getPropertiesHistoricTenanciesRecords')
    },
    fetchLandlordRecords({ dispatch }) {
      dispatch('LandlordsRecords/getAllLandlordsRecords')
      dispatch('LandlordsRecords/getLandlordsBanklessRecords')
      dispatch('LandlordsRecords/getLandlordsEmaillessRecords')
      dispatch('LandlordsRecords/getLandlordsOwingRecords')
      dispatch('LandlordsRecords/getLandlordsTenPercentDifferenceRecords')
    },
    fetchContractorRecords({ dispatch }) {
      dispatch('ContractorsRecords/getAllContractorsRecords')
      dispatch('ContractorsRecords/getContractorsBanklessRecords')
      dispatch('ContractorsRecords/getContractorsEmaillessRecords')
      dispatch('ContractorsRecords/getContractorsOnHold')
    },
    fetchOpenBankingData({ dispatch }) {
      dispatch('OpenBankingRequests/getBankAccounts')
    },

    // LOGIN / AUTHENTICATION + SESSION-STATUS & BRANCH SELECTOR
    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
    
    login({commit, dispatch, state}, credentials) {
      return axios({
        method: 'POST',
        url: `${state.baseURL}/corporate/agentpay/letmcletting/session/login`,
        data: {
          Username: credentials.username,
          Password: credentials.password,
        },
        headers: {
         api_key: state.apiKey
        },
      })
      .then(({data}) => {
        commit('SET_USER_DATA', data)
        dispatch('sessionStatus')
        dispatch('CurrencyData/getCurrencyData')
      })
      
    },
    loginAgentOSSessionID({commit, dispatch, state}, credentials) {
      return axios({
        method: 'POST',
        url: `${state.baseURL}/corporate/agentpay/letmcletting/session/loginsessionid`,
        data: {
          ShortName: credentials.shortName,
          SessionID: credentials.sessionID,
        },
        headers: {
         api_key: state.apiKey
        },
      })
      .then(({data}) => {
        commit('SET_USER_DATA', data)
        dispatch('sessionStatus')
        dispatch('CurrencyData/getCurrencyData')
      })
    },
    sessionStatus({state, commit, dispatch}) {
      return axios({
        method: 'GET',
        url: `${state.baseURL}/corporate/agentpay/letmcletting/${state.user.ClientName}/session/status`,
        headers: {
          token: state.user.LoginToken,
          api_key: state.apiKey
        }
      })
      .then( ({data}) => {
        if(data.MultiBranchAccess == true) {
          commit('SET_MULTI_BRANCH_USER', data)
          dispatch('getAllBranches')
        } else {
            commit('SET_SINGLE_BRANCH_USER', data)
          }
       })
       .catch( () => {
         dispatch('logout')
       })
    },
    getAllBranches({commit, state}) {
      return axios({
        method: 'GET',
        url: `${state.baseURL}/corporate/agentpay/letmcletting/${state.user.ClientName}/company/branches/0/1000`,
        headers: {
          token: state.user.LoginToken,
          api_key: state.apiKey,
        }
      })
      .then(({data}) => {
        const allBranches = data.Data
        const alphabeticallySorted = allBranches.sort( function(a,b) {
          let nameA = a.Name.toLowerCase();
          let nameB = b.Name.toLowerCase();
          if(nameA < nameB) return -1
          if(nameA > nameB) return 1
          return 0
        })
        alphabeticallySorted.unshift({
          Name: '*** Company Wide',
          OID: null
        }),
        commit('SET_ALL_BRANCHES', alphabeticallySorted)
      })
    },
    setSelectedBranch({commit}, branchObj) {
      commit('SET_SELECTED_BRANCH', branchObj)
    },
    logout({commit, state}) {
      return axios({
        method: 'DELETE',
        url: `${state.baseURL}/corporate/agentpay/letmcletting/${state.user.ClientName}/session/logout`,
        headers: {
          token: state.user.LoginToken,
          api_key: state.apiKey
        }
      })
      .then( () => {
        commit('CLEAR_USER_DATA')
      })
    },
    // MESSAGE CONTROLLER in MessageDialog component
    // Used on various records tables 
    // ------------------------------------------------------------------------
    sendMessage({state}, payload) {
      // console.log('Message payload ', payload );
      console.log('state', state)
      console.log('attachemnts', payload.attachments)
      return axios({
        method: 'POST',
        url: `${state.baseURL}/corporate/agentpay/letmcletting/${state.user.ClientName}/messaging/comment/submit`,
        headers: {
          token: state.user.LoginToken,
          api_key: state.apiKey,
        },
        params: {
          recordID: payload.recordID,
          source: payload.source,
          comment: payload.comment,
          commentRecipient: 'AgentPay' // HARD-CODED - distinguishes it from support
        },
        // data: {
        //   attachments: payload.attachments
        // }
        data: payload.attachments
      })
    },
    getSpecificPersonById({state}, personID) {
      return axios({
        method: 'GET',
        url: `${state.baseURL}/corporate/agentpay/letmcletting/${state.user.ClientName}/company/person/${personID}`,
        headers: {
          token: state.user.LoginToken,
          api_key: state.apiKey,
        }
      })
    },
    getSalesChargeTypes({state, commit}) {
      return axios({
        method: 'GET',
        url: `${state.baseURL}/corporate/agentpay/letmcletting/${state.user.ClientName}/company/salestypes`,
        headers: {
          token: state.user.LoginToken,
          api_key: state.apiKey,
        }
      })
      .then( ({data}) => {
        commit('SET_SALES_CHARGE_TYPES', data.Data)
      })
    },
    getNominalCodes({state, commit}) {
      return axios({
        method: 'GET',
        url: `${state.baseURL}/corporate/agentpay/letmcletting/${state.user.ClientName}/company/nominalcodes`,
        headers: {
          token: state.user.LoginToken,
          api_key: state.apiKey,
        }
      })
      .then( ({data}) => {
        commit('SET_NOMINAL_CODES', data.Data)
      })
    },
    // Test apis ---------------------------------------------------------------------------------
    // Currently testing with unreconciledPayments module
    axiosGet({state}, options) {
      // console.log('Calling axiosGet');
      return axios({
        method: 'GET',
        url: `${state.baseURL}/corporate/agentpay/letmcletting/${state.user.ClientName}/${options.url}`,
        headers: {
          token: state.user.LoginToken,
          api_key: state.apiKey,
        },
        params: options.params
        // How to set branchID as detaulf params whilst allowing for additional params
        // params: {
        //   branchID: rootState.branchID, 
        //   ...options.params
        // }

      })
    }
  },
  getters: {
    loggedIn(state) {
      return !!state.user
    },
    returnUserFirstName(state) {
      let activeUser = state.activeUser
      var firstName = activeUser.replace(/ .*/,'')
      return firstName
    },
    landlordsOnHold(state) {
      const landlordsToPay = state.OutgoingPayments.landlordsToPay
      let onHold = landlordsToPay.filter(landlords => landlords.OnHold == true)
      return onHold.length
    },
    tenantsOnHold(state) {
      const tenantsToPay = state.OutgoingPayments.tenantsToPay
      let onHold = tenantsToPay.filter(tenant => tenant.OnHold == true)
      return onHold.length
    },
    contractorsOnHold(state) {
      const contractorsToPay = state.OutgoingPayments.contractorsToPay
      let onHold = contractorsToPay.filter(contractor => contractor.OnHold == true)
      return onHold.length
    },
    getPropertyCountAverage(state) {
      const array = state.DashboardCards.portfolioGrowth
      return array.map(el => el.PropertyCountPerformance).reduce((acc, val) => acc + val) / array.length
    },
    getRentRollPerformanceAverage(state) {
      const array = state.DashboardCards.portfolioGrowth
      return array.map(el => el.RentRollPerformance).reduce((acc, val) => acc + val) / array.length
    },
    inArrearsUnder14(state) {
      const under14 = state.TenantsRecords.tenantsArrearsRecords.Data
      return under14.filter(tenant => tenant.TenantInDebtDuration < 14 )
    },
    inArrears14To31(state) {
      const array = state.TenantsRecords.tenantsArrearsRecords.Data
      return array.filter(tenant => tenant.TenantInDebtDuration > 14 && tenant.TenantInDebtDuration <= 31)
    },
    inArrears32To62(state) {
      const array = state.TenantsRecords.tenantsArrearsRecords.Data
      return array.filter(tenant => tenant.TenantInDebtDuration > 31 && tenant.TenantInDebtDuration <= 62)
    },
    inArrearsOver62(state) {
      const array = state.TenantsRecords.tenantsArrearsRecords.Data
      return array.filter(tenant => tenant.TenantInDebtDuration > 62)
    },
  }
})



