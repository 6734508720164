<template lang="html">
  <v-overlay :value="overlay">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  props: ['overlay'],
  data() {
    return {
      // overlay: true,
    }
  },
}
</script>

<style lang="css" scoped>
</style>
