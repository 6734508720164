import axios from 'axios'

export default {
  namespaced: true,
  state: {
    propertiesManagedRecords: '',
    propertiesWithoutOwnerRecords: '',
    propertiesRentCollectedRecords: '',
    propertiesWithoutTenantsRecords: '',
    propertiesNotManagedRecords: '',
    propertiesNewTenanciesRecords: '',
    propertiesHistoricRecords: ''
  },
  mutations: {
    SET_PROPERTIES_MANAGED(state, data) {
      state.propertiesManagedRecords = data
    },
    SET_PROPERTIES_OWNERLESS(state, data) {
      state.propertiesWithoutOwnerRecords = data
    },
    SET_PROPERTIES_RENT_COLLECTED(state, data) {
      state.propertiesRentCollectedRecords = data
    },
    SET_PROPERTIES_WITHOUT_TENANTS(state, data) {
      state.propertiesWithoutTenantsRecords = data
    },
    SET_PROPERTIES_NOT_MANAGED(state, data) {
      state.propertiesNotManagedRecords = data
    },
    SET_PROPERTIES_NEW_TENANCIES(state, data) {
      state.propertiesNewTenanciesRecords = data
    },
    SET_PROPERTIES_HISTORIC_TENANCIES(state, data){
      state.propertiesHistoricRecords = data;
    }
  },
  actions: {
    getPropertiesManagedRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/managed/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_PROPERTIES_MANAGED', data.Data)
        })
    },
    getPropertiesOwnerlessRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/ownerless/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_PROPERTIES_OWNERLESS', data.Data)
        })
    },
    getPropertiesRentCollectedRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/rentCollect/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_PROPERTIES_RENT_COLLECTED', data.Data)
        })
    },
    getPropertiesWithoutTenantsRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/tenantless/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_PROPERTIES_WITHOUT_TENANTS', data.Data)
        })
    },
    getPropertiesNotManagedRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/unmanaged/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_PROPERTIES_NOT_MANAGED', data.Data)
        })
    },
    getPropertiesNewTenanciesRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/newtenancies/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_PROPERTIES_NEW_TENANCIES', data.Data)
        })
    },
    getPropertiesHistoricTenanciesRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/historictenancies/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_PROPERTIES_HISTORIC_TENANCIES', data.Data)
        })
    },
  }
}