import axios from 'axios'

export default {
  namespaced: true,
  state: {
    tenants: '',
  },
  mutations: {
    SET_TENANTS(state, data) {
      state.tenants = data
    },
  },
  actions : {
    // Multiple Axios request -----------------------
    getTenantsCount({commit, dispatch}) {
      return axios.all([
        dispatch('getTenantsActive'),
        dispatch('getTenantsArrears'),
        dispatch('getTenantsNotChased'),
        dispatch('getTenantsHistoric')
      ])
        .then(axios.spread( (active, arrears, notChased, historic) => {
          commit('SET_TENANTS', {
            active: active.data,
            inArrears: arrears.data,
            notChased: notChased.data,
            historic: historic.data
          })
        }))
      },
      // Requesting following 3 API calls
      getTenantsActive({rootState}) {
        return axios({
          method: 'GET',
          url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/tenants/active/count`,
          headers: {
            token: rootState.user.LoginToken,
            api_key: rootState.apiKey,
          },
          params: {
            branchID: rootState.branchID
          }
        })
      },
      getTenantsHistoric({rootState}) {
        return axios({
          method: 'GET',
          url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/tenants/historic/count`,
          headers: {
            token: rootState.user.LoginToken,
            api_key: rootState.apiKey,
          },
          params: {
            branchID: rootState.branchID
          }
        })
      },
      getTenantsArrears({rootState}) {
        return axios({
          method: 'GET',
          url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/tenants/arrears/count`,
          headers: {
            token: rootState.user.LoginToken,
            api_key: rootState.apiKey,
          },
          params: {
            branchID: rootState.branchID
          }
        })
      },
      getTenantsNotChased({rootState}) {
        return axios({
          method: 'GET',
          url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/tenants/notChased/count`,
          headers: {
            token: rootState.user.LoginToken,
            api_key: rootState.apiKey,
          },
          params: {
            branchID: rootState.branchID
          }
        })
      },
    }
}