import axios from 'axios'

export default {
  namespaced: true,
  state: {
    forecastReports: ''
  },
  mutations: {
    SET_MANAGEMENT_FEE_FORECASTS(state, data) {
      state.forecastReports = data
    }
  },
  actions: {
    getManagementFeeForecasts({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/reporting/fees/forecast`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID,
        }
      })
      .then(({data}) => {
        commit('SET_MANAGEMENT_FEE_FORECASTS', data.Data)
      })
      .catch( err => console.log(err))
    },
  },
  getters: {
    reports(state){
        return state.forecastReports;
    }
  }
}