import axios from 'axios'

export default {
  namespaced: true,
  state: {
    tenantsActiveRecords: '',
    tenantsArrearsRecords: '',
    tenantsArrearsLessThan61Days: '',
    tenantsArrearsOver61Days: '',
    tenantsNotChasedRecords: '',
    tenantsHistoricRecords: ''
  },
  mutations: {
    SET_TENANTS_ACTIVE_RECORDS(state, data) {
      state.tenantsActiveRecords = data
    },
    SET_TENANTS_ARREARS_RECORDS(state, data) {
      state.tenantsArrearsRecords = data
      state.tenantsArrearsLessThan61Days = data.Data.filter(tenant => tenant.TenantInDebtDuration <= 61)
      state.tenantsArrearsOver61Days = data.Data.filter(tenant => tenant.TenantInDebtDuration > 61)
    },
    SET_TENANTS_NOT_CHASED_RECORDS(state, data) {
      state.tenantsNotChasedRecords = data
    },
    SET_TENANTS_HISTORIC_RECORDS(state, data) {
      state.tenantsHistoricRecords = data
    },
  },
  actions: {
    downloadStoGuide({rootState}, payload) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/tenants/${payload}/sto`,
        responseType: 'blob',
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
    })
      .then((response) => {
           var fileURL = window.URL.createObjectURL(new Blob([response.data]));
           var fileLink = document.createElement('a');
           fileLink.href = fileURL
           fileLink.setAttribute('download', 'file.pdf');
           document.body.appendChild(fileLink);
           fileLink.click();
      })
    },
    getTenantsActiveRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/tenants/active/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
      .then(({data}) => {
        commit('SET_TENANTS_ACTIVE_RECORDS', data)
      })
    },
    getTenantsArrearsRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/tenants/arrears/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
      .then(({data}) => {
        commit('SET_TENANTS_ARREARS_RECORDS', data)
      })
    },
    getTenantsNotChasedRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/tenants/notChased/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
      .then(({data}) => {
        commit('SET_TENANTS_NOT_CHASED_RECORDS', data)
      })
    },
    getTenantsHistoricRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/tenants/historic/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
      .then(({data}) => {
        commit('SET_TENANTS_HISTORIC_RECORDS', data)
      })
    },
  }
} 