// Unreconciled Payments
// Outgoing Payments
// Insights
// Income
// Portfolio Growth

import axios from 'axios'

export default {
  namespaced: true,
  state: {
    unreconciledPayments: '',
    outGoingPayments: '',
    insights: '',
    income: '',
    portfolioGrowth: '',
  },
  mutations: {
    SET_UNRECONCILED_PAYMENTS(state, data) {
      state.unreconciledPayments = data
    },
    SET_OUTGOING_PAYMENTS(state, data) {
      state.outGoingPayments = data
    },
    SET_INSIGHTS(state, data) {
      state.insights = data
    },
    SET_INCOME(state, data) {
      state.income = data
    },
    SET_PORTFOLIO_GROWTH(state, data) {
      state.portfolioGrowth = data
    },
  },
  actions: {
    getUnreconciledPayments({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/payments/unreconciled/count`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_UNRECONCILED_PAYMENTS', data)
        })
        .catch( err => {
          commit('SET_ERROR', err, { root: true })
        })
    },
    getOutGoingPayments({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/outgoingPayments/summary`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_OUTGOING_PAYMENTS', data)
        })
    },
    getInsights({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/dashboard/insights`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_INSIGHTS', data)
        })
    },
    getIncome({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/dashboard/income`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_INCOME', data)
        })
    },
    getPortfolioGrowth({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/dashboard/portfoliogrowth`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_PORTFOLIO_GROWTH', data.Nodes)
        })
    },
  }
}

// LandlordsCount.js
// PropertiesCount.js
// TenantsCount.sj 
// for more dashboard cards