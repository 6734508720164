<template>
  <v-app>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script>
// Autologout article: https://css-tricks.com/detecting-inactive-users/
window.zESettings = {
  webWidget: {
    color: {
      theme: "#FF69B4",
      launcher: "#CC3A83", // This will also update the badge
      launcherText: "#E589B7",
      button: "#8A0648",
      resultLists: "#691840",
      header: "#203D9D",
      articleLinks: "#FF4500",
    },
  },
};

export default {
  data() {
    return {
      onLine: navigator.onLine,
      showBackOnline: false,
    };
  },
  mounted() {
    console.log("*** AgentPay front-end is built by Claudio Rescigno ***");

    // Chat plugin
    $zopim(function () {
      // eslint-disable-line
      $zopim.livechat.theme.setColor("#f26642"); // eslint-disable-line
      $zopim.livechat.window.setTitle("AgentOS Support"); // eslint-disable-line
    });

    // Check for dashboard settings
    if (localStorage.alternativeDashboard) {
      if (localStorage.alternativeDashboard === "true") {
        this.$store.commit("SET_ALTERNATIVE_DASHBOARD", true);
      } else {
        this.$store.commit("SET_ALTERNATIVE_DASHBOARD", false);
      }
    }
    // Check for theme settings
    if (localStorage.agentPayLightTheme) {
      if (localStorage.agentPayLightTheme === "true") {
        this.$store.commit("SET_LIGHT_THEME", true);
        this.$vuetify.theme.light = true;
        this.$vuetify.theme.dark = false;
      } else {
        this.$store.commit("SET_LIGHT_THEME", false);
        this.$vuetify.theme.dark = true;
        this.$vuetify.theme.light = false;
      }
    }

    //offline detection
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      } else {
        alert(
          "\nIt looks like you've lost your Internet connection! \n\nThe details on this page might have changed while you were offline. \n\nWhen you're connected again, make sure you refresh the page before continuing."
        );
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>

<style>
.twitter-wrapper {
  max-height: 472px;
  border-radius: 5px;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.twitter-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.twitter-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.v-overlay__scrim {
  background-color: #40424c !important;
}

.container {
  padding-top: -30px !important;
}

/* Transitions =============================================== */
/* Fade enter =================== */
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

/* Slide transition ====================== */
.slide-fade-enter {
  transform: translateY(30px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease-in;
}

.slide-fade-leave-to {
  opacity: 0;
}

/* Slide DOWN ====================== */
.slide-down-fade-enter,
.slide-down-fade-delay-enter {
  transform: translateY(-50px);
  opacity: 0;
}

.slide-down-fade-enter-active,
.slide-down-fade-leave-active {
  transition: all 0.4s ease-in-out;
}

.slide-down-fade-delay-enter-active,
.slide-down-fade-delay-leave-active {
  transition: all 0.7s ease-in-out;
}

.slide-down-fade-leave-to,
.slide-down-fade-delay-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}

/* Slide UP ====================== */
.slide-up-fade-enter,
.slide-up-fade-delay-enter {
  transform: translateY(50px);
  opacity: 0;
}

.slide-up-fade-enter-active,
.slide-up-fade-leave-active {
  transition: all 0.5s ease-in-out;
}

.slide-up-fade-delay-enter-active,
.slide-up-fade-delay-leave-active {
  transition: all 0.7s ease-in-out;
}

.slide-up-fade-leave-to,
.slide-up-fade-delay-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}

/* Slide LEFT ====================== */
.slide-left-fade-enter,
.slide-left-fade-delay-enter {
  transform: translateX(50px);
  opacity: 0;
}

.slide-left-fade-enter-active,
.slide-left-fade-leave-active {
  transition: all 0.5s ease-in-out;
}

.slide-left-fade-delay-enter-active,
.slide-left-fade-delay-leave-active {
  transition: all 0.7s ease-in-out;
}

.slide-left-fade-leave-to,
.slide-left-fade-delay-leave-to {
  transform: translateX(-30px);
  opacity: 0;
}

/* Slide RIGHT ====================== */
.slide-right-fade-enter,
.slide-right-fade-delay-enter,
.slide-right-fade-delay2-enter {
  transform: translateX(-50px);
  opacity: 0;
}

.slide-right-fade-enter-active,
.slide-right-fade-leave-active {
  transition: all 0.5s ease-in-out;
}

.slide-right-fade-delay-enter-active,
.slide-right-fade-delay-leave-active {
  transition: all 0.6s ease-in-out;
}

.slide-right-fade-delay2-enter-active,
.slide-right-fade-delay2-leave-active {
  transition: all 0.7s ease-in-out;
}

.slide-right-fade-leave-to,
.slide-right-fade-leave-to,
.slide-right-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}

/* Increases font size on all tables on mobile */
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row td {
  font-size: 1.2rem;
}

/* Colours alternative table rows on mobile */
.v-data-table
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row:nth-child(even)
  td {
  background: #f5f5f5;
}
</style>
