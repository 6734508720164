// import axios from 'axios'

export default {
  namespaced: true,
  state: {
    unreconciledPaymentsRecords: '',
    reconciledPaymentsRecords: '',

  },
  mutations: {
    SET_UNRECONCILED_PAYMENTS_RECORDS(state, data) {
      state.unreconciledPaymentsRecords = data
    },
    SET_RECONCILED_PAYMENTS_RECORDS(state, data) {
      state.reconciledPaymentsRecords = data
    },
  },
  actions: {
    // Testing calling a single axiosGet action in store/index.js
    getUnreconciledPaymentsRecords({commit, dispatch, rootState}) {
      dispatch('axiosGet', {
        url: 'payments/unreconciled/records',
        params: {
          branchID: rootState.branchID
        }
      }, { root: true })
        .then(({data}) => {
          commit('SET_UNRECONCILED_PAYMENTS_RECORDS', data)
        })
    },
    // getUnreconciledPaymentsRecords({commit, rootState}) {
    //   return axios({
    //     method: 'GET',
    //     url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/payments/unreconciled/records`,
    //     headers: {
    //       token: rootState.user.LoginToken,
    //       api_key: rootState.apiKey,
    //     },
    //     params: {
    //       branchID: rootState.branchID
    //     }
    //   })
    //     .then(({data}) => {
    //       commit('SET_UNRECONCILED_PAYMENTS_RECORDS', data)
    //     })
    // },
    getReconciledPaymentsRecords({commit, dispatch, rootState}) {
      dispatch('axiosGet', {
        url: 'paymentgroups/records',
        params: {
          branchID: rootState.branchID
        }
      }, { root: true })
        .then(({data}) => {
          commit('SET_RECONCILED_PAYMENTS_RECORDS', data)
        })
    },
    // getReconciledPaymentsRecords({commit, rootState}) {
    //   return axios({
    //     method: 'GET',
    //     url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/paymentgroups/records`,
    //     headers: {
    //       token: rootState.user.LoginToken,
    //       api_key: rootState.apiKey,
    //     },
    //     params: {
    //       branchID: rootState.branchID
    //     }
    //   })
    //     .then(({data}) => {
    //       commit('SET_RECONCILED_PAYMENTS_RECORDS', data)
    //     })
    // },
  }
}