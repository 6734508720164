import axios from 'axios'

export default {
  namespaced: true,
  state: {
    landlordsToPay: '',
    contractorsToPay: '',
    tenantsToPay: '',
    zeroBalance: ''
  },
  mutations: {
    SET_LANDLORDS_PAYMENTS(state, data) {
      state.landlordsToPay = data
    },
    SET_TENANTS_PAYMENTS(state, data) {
      state.tenantsToPay = data
    },
    SET_CONTRACTORS_PAYMENTS(state, data) {
      state.contractorsToPay = data
    },
    SET_ZERO_BALANCE_PAYMENTS(state, data) {
      state.zeroBalance = data
    },
  },
  getters:{
    getAllOutgoingPayments(state) {
      if(state.zeroBalance === ''){
        return null;
      }
      else{
      let concatenated = [];
      concatenated = [state.landlordsToPay, state.contractorsToPay, state.tenantsToPay, state.zeroBalance]; 

  let set = new Set();
  let unionArray = concatenated.flat().filter(item => {
    if (!set.has(item.PayeeID) && set !== "") {
      set.add(item.PayeeID);
      return true;
      }
      return false;
        }, set);

      var filtered = unionArray.filter(function (el) {
          return el != "";
      });
      console.log(filtered);

      return filtered;
    }
    },
  },
  actions: {
    pausePayment({rootState}, payload) {
      return axios({
        method: 'POST',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/outgoingPayments/togglePause?personID=${payload}`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
      })
    },
    downloadInvoice({rootState}, payload) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/download/invoice?invoiceID=${payload}`,
        responseType: 'blob',
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        }
    })
  },
  getLandlordsPayments({commit, rootState}) {
    return axios({
      method: 'GET',
      url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/outgoingPayments/landlords/records`,
      headers: {
        token: rootState.user.LoginToken,
        api_key: rootState.apiKey,
      },
      params: {
        branchID: rootState.branchID
      }
    })
      .then(({data}) => {
        commit('SET_LANDLORDS_PAYMENTS', data.ToPay)
      })
    },
    getTenantsPayments({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/outgoingpayments/tenants/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_TENANTS_PAYMENTS', data.ToPay)
        })
    },
    getContractorsPayments({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/outgoingpayments/contractors/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_CONTRACTORS_PAYMENTS', data.ToPay)
        })
    },
    getZeroBalancePayments({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/outgoingpayments/all/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_ZERO_BALANCE_PAYMENTS', data.ToPay)
        })
    },
  }
}