import axios from 'axios'

export default {
  namespaced: true,
  state: {
    bankAccounts: ''
  },
  mutations: {
    SET_BANK_ACCOUNTS(state, data) {
      state.bankAccounts = data
    }
  },
  actions: {
		getBankAccounts({rootState, commit} ) {
			return axios({
				method: 'GET',
				url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/openbanking/accounts/system`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        }
      })
      .then( ({data}) => {
        commit('SET_BANK_ACCOUNTS', data)
      })
      .catch( err => console.log(err))
    },
    authorizeAccount({rootState}, bankID) {
			return axios({
				method: 'POST',
				url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/openbanking/authorize/${bankID}`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        }
      })
    },
    linkableAccount({rootState}, bankID) {
			return axios({
				method: 'GET',
				url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/openbanking/accounts/system/${bankID}/linkableAccounts`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        }
      })
    },
    linkAccount({rootState}, credentials) {
			return axios({
				method: 'POST',
				url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/openbanking/accounts/system/${credentials.systemBancAccountID}/link/${credentials.openBankingAccountID}`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        }
      })
    },
    unlinkAccount({rootState}, accountID) {
			return axios({
				method: 'POST',
				url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/openbanking/accounts/system/${accountID}/unlink`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        }
      })
    },
  }
}