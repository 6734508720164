import axios from 'axios'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    getBankAccounts({rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/company/bankaccounts`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
    },
    getPaymentRequests({ rootState }, payload) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/payments`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          showOneOffPayments: true,
          showStoPayments: true,
          from: '2000-09-21T08:35:00.000Z',
          to: payload.dateTo,
          personID: payload.personID,
          branchID: rootState.branchID
        }
      })
    },
    createOneOffPayment({ rootState }, payload) {
      const submissionModel = {
        Description: payload.description,
        Amount: payload.amount,
        EntryType: payload.entryType
      }
      
      return axios({
        method: 'POST',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/payments/oneoff/${payload.personID}`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          accountID: payload.accountID
        },
        data: submissionModel
      })
    },
    createStoPayment({ rootState }, payload) {
      const submissionModel = {
        FirstPayment: payload.firstPayment,
        LastPayment: payload.lastPayment,
        Frequency: payload.frequency,
        Description: payload.description,
        Amount: payload.amount,
        EntryType: payload.entryType
      }
      
      return axios({
        method: 'POST',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/payments/sto/${payload.personID}`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          accountID: payload.accountID
        },
        data: submissionModel
      })
    },
    cancelPaymentRequest({ rootState }, paymentReference) {
      return axios({
        method: 'DELETE',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/payments/cancel/${paymentReference}`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        }
      })
    },

  }
}