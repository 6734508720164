import axios from 'axios'

export default {
  namespaced: true,
  state: {
    paidInvoices: ''
  },
  mutations: {
    SET_PAID_INVOICES(state, data) {
      state.paidInvoices = data
    }
  },
  actions: {
    getPaidInvoices({commit, rootState}, payload) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/reporting/invoicespaid`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID,
          monthToSearch: payload
        }
      })
      .then(({data}) => {
        commit('SET_PAID_INVOICES', data.Data)
      })
      .catch( err => console.log(err))
    },
    exportPaidInvoices({rootState}, payload) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/reporting/invoicespaid/export`,
        responseType: 'blob',
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID,
          monthToSearch: payload
        }
      })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'paidInvoiceExport.CSV');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch( err => console.log(err))
    },

  }
}