import axios from 'axios'

export default {
  namespaced: true,
  state: {
    landlordsBanklessRecords: '',
    landlordsEmaillessRecords: '',
    landlordsOwingRecords: '',
    landlordsTenPercentBalanceDifference: '',
    allLandlordRecords: ''
  },
  mutations: {
    SET_LANDLORDS_BANKLESS_RECORDS(state, data) {
      state.landlordsBanklessRecords = data
    },
    SET_LANDLORDS_EMAILLESS_RECORDS(state, data) {
      state.landlordsEmaillessRecords = data
    },
    SET_LANDLORDS_OWING_RECORDS(state, data) {
      state.landlordsOwingRecords = data
    },
    SET_LANDLORDS_TEN_PERCENT_DIFFERENCE_RECORDS(state, data) {
      state.landlordsTenPercentBalanceDifference = data
    },
    SET_ALL_LANDLORDS_RECORDS(state, data) {
      state.allLandlordRecords = data
    }
  },
  actions: {
    getAllLandlordsRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/landlords/all/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_ALL_LANDLORDS_RECORDS', data)
        })
    },
    getLandlordsBanklessRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/landlords/bankless/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_LANDLORDS_BANKLESS_RECORDS', data)
        })
    },
    getLandlordsEmaillessRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/landlords/emailless/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_LANDLORDS_EMAILLESS_RECORDS', data)
        })
    },
    getLandlordsOwingRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/landlords/owing/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_LANDLORDS_OWING_RECORDS', data)
        })
    },
    getLandlordsTenPercentDifferenceRecords({commit, rootState}) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/landlords/tenPercentBalanceDifference/records`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
      })
        .then(({data}) => {
          commit('SET_LANDLORDS_TEN_PERCENT_DIFFERENCE_RECORDS', data)
        })
    }
  }
}