<template>
  <v-card class="rounded-md">
    <v-card-title class="secondary white--text pa-3" id="card-title">
      <router-link :to="linkTo" class="decoration">
        <div class="d-flex ">
          <v-icon class="mr-3" color="white">
            <slot name="icon"></slot>
          </v-icon>
          <span class="subtitle-1 white--text font-weight-light">
            <slot name="title"></slot>
          </span>
        </div>
      </router-link>
    </v-card-title>

    <v-simple-table>
      <tbody @setColor="setColor" class="">
        <slot name="table"></slot>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
  export default {
    props: ['linkTo'],
    data () {
      return {
        myColor: '',
      }
    },
    methods: {
      setColor(value) {
        console.log('You sent us color: ' + value)
      }
    }
  }
</script>

<style scoped>
#card-title {
  text-decoration: none;
}

.decoration {
  text-decoration: none;
}
</style>
