import axios from 'axios'

export default {
  namespaced: true,
  state: {
    contractors: '',
  },
  mutations: {
    SET_CONTRACTORS(state, data) {
      state.contractors = data
    },
  },
	actions :  {
		// Multiple Axios request -----------------------
		getContractorsCount({commit, dispatch}) {
			return axios.all([
			dispatch('getContractorsBankless'),
			dispatch('getContractorsEmailless'),
			dispatch('getContractorsOnHold'),
			])
			.then(axios.spread( (bankless, emailless, onHold) => {
					commit('SET_CONTRACTORS', {
            bankless: bankless.data,
            emailless: emailless.data,
            onHold: onHold.data,
					})

			}))
    },
    // Following APIs called simultaneaously
		getContractorsBankless({rootState}) {
			return axios({
				method: 'GET',
				url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/contractors/bankless/count`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
			})
		},
		getContractorsEmailless({rootState}) {
			return axios({
				method: 'GET',
				url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/contractors/emailess/count`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
			})
		},
		getContractorsOnHold({rootState}) {
			return axios({
				method: 'GET',
				url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/contractors/onHold/count`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID
        }
			})
		},
	}
}