import axios from 'axios'

export default {
  namespaced: true,
  state: {
    RStBReport: ''
  },
  mutations: {
    SET_RSTB(state, data) {
      state.RStBReport = data
      console.log(state.RStBReport);
    }
  },
  actions: {
    getRStB({commit, rootState}, payload) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/reporting/RStB`,
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          monthToSearch: payload
        }
      })
      .then(({data}) => {
        
        commit('SET_RSTB', data.Results)
      })
      .catch( err => console.log(err))
    },
    exportRStB({rootState}, payload) {
      return axios({
        method: 'GET',
        url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/reporting/RStB/export`,
        responseType: 'blob',
        headers: {
          token: rootState.user.LoginToken,
          api_key: rootState.apiKey,
        },
        params: {
          branchID: rootState.branchID,
          monthToSearch: payload
        }
      })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'RStBexport.CSV');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch( err => console.log(err))
    },

  }
}