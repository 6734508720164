import axios from 'axios'

export default {
  namespaced: true,
  state: {
    properties: '',
  },
  mutations: {
    SET_PROPERTIES(state, data) {
      state.properties = data
    },
  },
  actions: {
    // Multiple Axios request -----------------------
    getPropertiesCount({commit, dispatch}) {
      return axios.all([
        dispatch('getPropertiesManaged'),
        dispatch('getPropertiesOwnerless'),
        dispatch('getPropertiesRentcollected'),
        dispatch('getPropertiesTenantless'),
        dispatch('getPropertiesUnmanaged'),
        dispatch('getPropertiesNewTenancies'),
        dispatch('getPropertiesHistoricTenancies')
      ])
        .then(axios.spread( (managed, ownerless, collected, tenantless, unmanaged, newTenancies, historicTenancies) => {
          console.log(historicTenancies);
          commit('SET_PROPERTIES', {
            managed: managed.data,
            ownerless: ownerless.data,
            collected: collected.data,
            tenantless: tenantless.data,
            unmanaged: unmanaged.data,
            newTenancies: newTenancies.data,
            historicTenancies: historicTenancies.data
          })
        }))
      },
      //Calling following 5 requests
      getPropertiesManaged({rootState}) {
        return axios({
          method: 'GET',
          url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/managed/count`,
          headers: {
            token: rootState.user.LoginToken,
            api_key: rootState.apiKey,
          },
          params: {
            branchID: rootState.branchID
          }
        })
      },
      getPropertiesOwnerless({rootState}) {
        return axios({
          method: 'GET',
          url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/ownerless/count`,
          headers: {
            token: rootState.user.LoginToken,
            api_key: rootState.apiKey,
          },
          params: {
            branchID: rootState.branchID
          }
        })
      },
      getPropertiesRentcollected({rootState}) {
        return axios({
          method: 'GET',
          url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/rentcollect/count`,
          headers: {
            token: rootState.user.LoginToken,
            api_key: rootState.apiKey,
          },
          params: {
            branchID: rootState.branchID
          }
        })
      },
      getPropertiesTenantless({rootState}) {
        return axios({
          method: 'GET',
          url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/tenantless/count`,
          headers: {
            token: rootState.user.LoginToken,
            api_key: rootState.apiKey,
          },
          params: {
            branchID: rootState.branchID
          }
        })
      },
      getPropertiesUnmanaged({rootState}) {
        return axios({
          method: 'GET',
          url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/unmanaged/count`,
          headers: {
            token: rootState.user.LoginToken,
            api_key: rootState.apiKey,
          },
          params: {
            branchID: rootState.branchID
          }
        })
      },
      getPropertiesNewTenancies({rootState}) {
        return axios({
          method: 'GET',
          url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/newtenancies/count`,
          headers: {
            token: rootState.user.LoginToken,
            api_key: rootState.apiKey,
          },
          params: {
            branchID: rootState.branchID
          }
        })
      },
      getPropertiesHistoricTenancies({rootState}) {
        return axios({
          method: 'GET',
          url: `${rootState.baseURL}/corporate/agentpay/letmcletting/${rootState.user.ClientName}/properties/historictenancies/count`,
          headers: {
            token: rootState.user.LoginToken,
            api_key: rootState.apiKey,
          },
          params: {
            branchID: rootState.branchID
          }
        })
      },
    }
}



