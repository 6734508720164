import Vue from 'vue'
import Router from 'vue-router'

// Lazy loading routes
// const AgentPay = () => import('./views/AgentPay.vue')
// const Home = () => import('./views/Home.vue')
// const Dashboard = () => import('./views/Dashboard.vue')
// const UnreconciledPayments = () => import('./views/UnreconciledPayments.vue')
// const OutgoingPayments = () => import('./views/OutgoingPayments.vue')
// const Tenants = () => import('./views/Tenants.vue')
// const Properties = () => import('./views/Properties.vue')
// const Landlords = () => import('./views/Landlords.vue')
// const Edits = () => import('./views/Edits.vue')
// const FAQ = () => import('./views/FAQ.vue')
// const Reports = () => import('./views/Reports.vue')
// const AddTenancy = () => import('./views/AddTenancy.vue')
// const AddContractor = () => import('./views/AddContractor.vue')
// const NotFound = () => import('./views/NotFound.vue')
// const Contractors = () => import('./views/Contractors.vue')
// const OpenBanking = () => import('./views/OpenBanking.vue')

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/Home.vue')
  },
  {
    path: '/index.html',
    component: () => import('./views/Home.vue'),
    alias: '/'
  },
  {
    path: '/agentpay',
    redirect: '/agentpay/dashboard',
    name: 'agentpay',
    component: () => import('./views/AgentPay.vue'),
    meta: { requiresAuth: true},
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('./views/Dashboard.vue')
      },
      {
        path: 'outgoing',
        name: 'outgoing',
        component: () => import('./views/OutgoingPayments.vue')
      },
      {
        path: 'invoices',
        name: 'Invoices',
        component: () => import('./views/Invoices.vue')
      },
      {
        path: 'unreconciled',
        name: 'unreconciled',
        component: () => import('./views/ClientAccounts.vue')
      },
      {
        path: 'tenants',
        name: 'tenants',
        component: () => import('./views/Tenants.vue')
      },
      {
        path: 'properties',
        name: 'properties',
        component: () => import('./views/Properties.vue')
      },
      {
        path: 'landlords',
        name: 'landlords',
        component: () => import('./views/Landlords.vue')
      },
      {
        path: 'edits',
        name: 'edits',
        component: () => import('./views/Edits.vue')
      },
      {
        path: 'faq',
        name: 'faq',
        component: () => import('./views/FAQ.vue')
      },
      {
        path: 'reports',
        name: 'reports',
        component: () => import('./views/Reports.vue')
      },
      {
        path: 'addTenancy',
        name: 'addTenancy',
        component: () => import('./views/AddTenancy.vue')
      },
      {
        path: 'addContractor',
        name: 'addContractor',
        component: () => import('./views/AddContractor.vue')
      },
      {
        path: 'contractors',
        name: 'contractors',
        component: () => import('./views/Contractors.vue')
      },
      {
        path: 'open-banking',
        name: 'openBanking',
        component: () => import('./views/OpenBanking.vue')
      },
      {
        path: 'payments/:id',
        name: 'Payments',
        props: true,
        component: () => import('./views/IndividualPayments.vue')
      },
      {
        path: 'payments',
        name: 'Payments',
        props: true,
        component: () => import('./views/AllPayments.vue')
      },
      {
        path: 'forecasts',
        name: 'Forecasts',
        props: true,
        component: () => import('./views/Forecasts.vue')
      },
    ]
    
  },
  {
    path: '/404',
    name: '404',
    component: () => import('./views/NotFound.vue')
  },
  {
    path: '*',
    redirect: { name: '404' }
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { y: 0 }
    }
  }
})

//checks routes for Authorization and if logged route
//  re-routes to homepage
// -------------------------------------------------------

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/')
  }
  next()
})

export default router
